import React, { Component } from 'react'
import Layout from '../../../components/layout'
import { Banner } from '../../../components/banner';
import LeftNavBar from '../../../containers/navbar'
import { navigate } from '@reach/router'
import Query from '../../../containers/netsclick/query';
import { FormGroup, Col, Badge, Table, Button, Row, Card, CardBody } from 'reactstrap';
import { isLoggedIn } from '../../../services/auth';
import { getVkeyAssetsDetails, getMidTidDetails, getSFTPDetails, getMAPHPPKeys, getNetsclickRefundStatus, decryptAndDownloadFile, getSnowREQStatus, getIntegrationStatus } from '../../../services/api'
import Merchantcard from '../../../containers/netsclick/merchantcard';
import { COLOR_CONSTANT, REFUND_APP_STATUS, MTLS_APP_STATUS, ENV_UAT, MENU_ITEM_TEMP_INT_MID_TID } from '../../../utils/constants';

export default class netsClick extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetched: false,
            selectedEnv: ENV_UAT,
            submitted: false,
            selectedMenu: MENU_ITEM_TEMP_INT_MID_TID,
            showPassword: false,
            vkeyValues: null,
            midtidValues: null,
            sftpValues: null,
            mtlsValues: null,
            mapHppData: null,
            refundInfo: null,
            uatCredentialsStatus: null,
            uatCredentialsReady: false,
            snowStatus: null,
            displaySFTPData : false
        }
    }

    async componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
    }
    displayPassword(showPassword) {
        this.setState({
            showPassword: !showPassword
        })
    }
    redirectPage(pageId) {
        navigate(pageId)
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }

    async componentWillMount() {
        let { selectedEnv, uatCredentialsReady } = this.state
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;

        if (application) {
            let netsclickAppID = application.nc_app_id
            let inputObj = {
                env: selectedEnv,
                netsclickAppID: netsclickAppID,
            }
            let data = await getIntegrationStatus({
                email: application.email,
                netsclickAppID: application.nc_app_id
            })
            let uatCredentialsStatus = null
            let signerStatus = data && data.length >= 1 ? data[1] : null

            if (signerStatus && signerStatus.length > 0) {
                uatCredentialsStatus = signerStatus.find(signers => signers.ENV_PROFILE == ENV_UAT)
            }
            let vkeyValue = await getVkeyAssetsDetails(inputObj)
            let midtid = await getMidTidDetails(inputObj)
            let sftp = null
            if(uatCredentialsStatus && uatCredentialsStatus.SFTP_STATUS){
               sftp =  await getSFTPDetails(inputObj)
            }
            let mapHppKeys = await getMAPHPPKeys({
                env: ENV_UAT,
                netsclickAppID: netsclickAppID,
            })
            let refundStatus = await getNetsclickRefundStatus(application.nc_app_id)
            vkeyValue = vkeyValue && vkeyValue.length > 0 ? vkeyValue : null
            if (midtid) {
                midtid = midtid.length == 1 && midtid[0].status == 'A' ? midtid[0] : null

            }
            if (sftp) {
                sftp = sftp.sftpData.length == 1 && sftp.sftpData[0].status == 'A' ? sftp : null

            }
            if (mapHppKeys) {
                mapHppKeys = mapHppKeys.length == 1 && mapHppKeys[0].status == 'A' ? mapHppKeys[0] : null

            }
            refundStatus = refundStatus && refundStatus.length == 1 ? refundStatus[0] : null
            if (uatCredentialsStatus && uatCredentialsStatus.APP_TYPE == 'P') {
                uatCredentialsReady = true
            }
            this.setState({
                vkeyValues: vkeyValue,
                midtidValues: midtid,
                sftpValues: sftp,
                mapHppData: mapHppKeys,
                refundInfo: refundStatus,
                uatCredentialsReady: uatCredentialsReady,
                uatCredentialsStatus: uatCredentialsStatus,
                displaySFTPData : uatCredentialsStatus ? uatCredentialsStatus.SFTP_STATUS : false,
                fetched: true
            })
        }
    }
    async displayMenuData(displayItem) {
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;

        let snowRequestType = null, snowStatus = null
        if (application) {
            if (displayItem == 'refund') {
                snowRequestType = "REFUND"
                snowStatus = await getNetsclickRefundStatus(application.nc_app_id)
                this.setState({
                    selectedMenu: displayItem,
                    snowStatus: snowStatus
                })
                return
            } else if (displayItem == 'sftp') {
                snowRequestType = "SFTP"
            } else if (displayItem == 'mtls') {
                snowRequestType = "MTLS"
            } else {
                this.setState({
                    selectedMenu: displayItem
                })
                return
            }
            let inputObj = {
                netsclickAppID: application.nc_app_id,
                deploymentenv: ENV_UAT,
                snowRequestType: snowRequestType
            }
            snowStatus = await getSnowREQStatus(inputObj)
        }

        this.setState({
            selectedMenu: displayItem,
            snowStatus: snowStatus ? snowStatus.result : null
        })

    }
    downloadFile(data) {

        let inputObj = {
            "email": data.uploaded_by,
            "netsclickAppID": data.nc_app_id,
            "env": ENV_UAT,
            "fileRef": "vkey",
            "fileName": data.filename
        }

        decryptAndDownloadFile(inputObj)
    }

    render() {

        let { fetched, selectedMenu, sftpValues, midtidValues, vkeyValues, showPassword, mapHppData, displaySFTPData,
            uatCredentialsReady, uatCredentialsStatus, snowStatus } = this.state
        selectedMenu = uatCredentialsReady ? selectedMenu : null
        if (!fetched) {
            return <div class="loading"></div>
        }
        let snowDisplayStatus = null

        if (selectedMenu == 'sftp') {
            snowDisplayStatus = snowStatus && (snowStatus[0].stage == "Completed" || snowStatus[0].stage == "complete" ) ? "Completed" : "In Progress"
        } else if (selectedMenu == 'mtls') {
            snowDisplayStatus = 'N'
            if (snowStatus && snowStatus.length == 1 && snowStatus[0].state !== "Completed") {
                snowDisplayStatus = 'P'
            }
            if (snowStatus && snowStatus.length == 1 && snowStatus[0].stage == "Completed") {
                snowDisplayStatus = 'A'
            }
        } else if (selectedMenu == 'refund') {
            snowDisplayStatus = snowStatus.length == 1 ? snowStatus[0].status : snowDisplayStatus
        }
        return (
            <Layout>
                <Banner title={`NETS Click`} />
                <div className='leftAligned'>

                    <FormGroup row>
                        <Col sm={9}>
                            <h4><b>Welcome to NETS Click developer portal page.</b></h4>
                        </Col>
                        <Merchantcard />
                    </FormGroup>
                </div>
                <FormGroup row >
                    <Col sm={2}>
                        <LeftNavBar menu='uatSetupCredentials' />
                    </Col>
                    <Col sm={7}>
                        <Row className='centerAlignedMenu'><div>
                            <Button
                                color="primary"
                                outline
                                href="/product/netsclick/uatSetupCredentials"
                            >
                                <b>Pre-requisites</b>{' '}
                                <Badge>
                                    (Merchant to Provide)
                                </Badge>
                            </Button>
                        </div>
                            <div>{'    '}</div>
                            <div>
                                <Button
                                    color="primary"
                                    outline
                                    active
                                >
                                    <b>Credentials</b> {' '}
                                    <Badge>
                                        (Merchant to Download)
                                    </Badge>
                                </Button>
                            </div></Row>


                        <div>{uatCredentialsReady == true ? <FormGroup row className='leftAligned topAlignedMedium'>
                            <ul class="breadcrumbs-nc">
                                <li onClick={() => this.displayMenuData('midtid')} className={selectedMenu == 'midtid' ? 'breadcrumbs-nc-item applyNcActive' : 'breadcrumbs-nc-item '}><b> MID/TID</b></li>
                                <li onClick={() => this.displayMenuData('sftp')} className={selectedMenu == 'sftp' ? 'breadcrumbs-nc-item applyNcActive' : 'breadcrumbs-nc-item '}><b>SFTP Details</b></li>
                                <li onClick={() => this.displayMenuData('vkey')} className={selectedMenu == 'vkey' ? 'breadcrumbs-nc-item applyNcActive' : 'breadcrumbs-nc-item '}><b>vKey Asset</b></li>
                                <li onClick={() => this.displayMenuData('map')} className={selectedMenu == 'map' ? 'breadcrumbs-nc-item applyNcActive' : 'breadcrumbs-nc-item '}>  <b>MAP Keys</b></li>
                                <li onClick={() => this.displayMenuData('refund')} className={selectedMenu == 'refund' ? 'breadcrumbs-nc-item applyNcActive' : 'breadcrumbs-nc-item '}> <b>Refund</b></li>
                                <li onClick={() => this.displayMenuData('mtls')} className={selectedMenu == 'mtls' ? 'breadcrumbs-nc-item applyNcActive' : 'breadcrumbs-nc-item '}> <b>mTLS</b></li>
                                <li onClick={() => this.displayMenuData('api')} className={selectedMenu == 'api' ? 'breadcrumbs-nc-item applyNcActive' : 'breadcrumbs-nc-item'}> <b>API Credentials</b></li>
                            </ul>
                        </FormGroup> :
                            <Card className="MessageCard " outline
                                color="success" >

                                <CardBody>

                                    <div>You have not provided the necessary pre-requsites required to setup the credentials</div>
                                    <a href='/product/netsclick/uatSetupCredentials'>Please proceed to upload/provide the pre-requsities</a>
                                </CardBody>
                            </Card>

                        } </div>
                        <div>{selectedMenu == MENU_ITEM_TEMP_INT_MID_TID ?
                            <div> {midtidValues && midtidValues.mid ? <Table className="centerAligned width-min application" striped bordered>
                                <thead>
                                    <tr>
                                        <th>MID</th>
                                        <th>TID</th>
                                        <th> Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{midtidValues ? midtidValues.mid : ''}</td>
                                        <td>{midtidValues ? midtidValues.tid.replace(';', ';\n') : ''}</td>
                                        <td>{midtidValues ? midtidValues.comments : ''}</td>
                                    </tr>
                                </tbody>
                            </Table> : <Card className='padding-table leftAligned' >
                                <div>Your dedicated MID/TID is not ready yet</div>
                                <div onClick={() => this.redirectPage('/product/netsclick/commoncredentials')} href='#'><u>Click here to view common credentials to start integration</u></div>
                            </Card>}</div>

                            : ''}  </div>
                        <div>{selectedMenu == 'sftp' ? <div>{
                            displaySFTPData ? <div>
                                <div><Table className="centerAligned width-min application" striped bordered>
                                    <tbody>
                                        <tr>
                                            <td>SFTP URL</td>
                                            <td><u>{sftpValues && sftpValues.sftpData ? sftpValues.sftpData[0].sftp_url : ''}</u></td>
                                        </tr>
                                        <tr>
                                            <td>SFTP Username</td>
                                            <td>{sftpValues && sftpValues.sftpData ? sftpValues.sftpData[0].username : ''}</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2}>SFTP Password</td>
                                            <td onClick={() => this.displayPassword(showPassword)}> {(showPassword && sftpValues) ? atob(sftpValues.password) : <div>&#128065;</div>} </td>
                                        </tr>
                                        <tr><td>This is only for first time login Password. You will be prompted to reset upon first time login.</td></tr>
                                        <tr>
                                            <td>SFTP Folder</td>
                                            <td>{sftpValues && sftpValues.sftpData ? sftpValues.sftpData[0].folder_path : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Merchant Source IP</td>
                                            <td>{uatCredentialsStatus ? uatCredentialsStatus.IP_ADDRESS : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>IP Whitelisting</td>
                                            <td> <Badge color={COLOR_CONSTANT['C']}>{snowDisplayStatus ? snowDisplayStatus : "In Progress"}</Badge></td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                    </div>
                            </div> : <div>
                            <Card className='padding-table leftAligned' ><strong>You have indicated that you do not require SFTP access.</strong>
                            <strong>Please reach out to your RM if you would like to request for access</strong>
                            </Card>
                            </div>
                            }</div> : ''}</div>
                        <div>{selectedMenu == 'vkey' ? <div>
                            <Table className="centerAligned width-min application" striped bordered>
                                <thead>
                                    <tr>
                                        <th>OS Type</th>
                                        <th>File</th>
                                        <th>Version</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {vkeyValues ? vkeyValues.map(data => {
                                        return (
                                            <tr>
                                                <td>{data ? data.os_type == 'A' ? 'Android' : 'IOS' : ''} </td>
                                                <td href='' onClick={() => this.downloadFile(data)}><u>{data ? data.filename : ''}</u></td>
                                                <td>{data ? data.version : ''}</td>
                                                <td>{data ? data.comments : ''}</td>
                                            </tr>
                                        )
                                    }) : ''}
                                </tbody>
                            </Table>
                        </div> : ''}</div>
                        <div>{selectedMenu == 'map' ? <div>
                            <Table classNme='leftAlignedMedium' bordered>
                                <thead>
                                    <tr className="table-success">
                                        <th>
                                            IOS
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td className="table-success">
                                            <b> App ID</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.ios_app_id : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>App Secret</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.ios_app_sec : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>Wallet ID</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.ios_wallet_id : ''}
                                        </td>
                                    </tr>
                                </tbody>

                            </Table>
                            <Table classNme='leftAlignedMedium' bordered>
                                <thead>
                                    <tr className="table-success">
                                        <th>
                                            Android
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td className="table-success">
                                            <b> App ID</b>
                                        </td>
                                        <td>
                                            {mapHppData ? mapHppData.and_app_id : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>App Secret</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.and_app_sec : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>Wallet ID</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.and_wallet_id : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table classNme='leftAlignedMedium' bordered>
                                <thead>
                                    <tr className="table-success">
                                        <th>
                                            MAP Public Key (same for all merchant in prod)
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td className="table-success">
                                            <b> ID</b>
                                        </td>
                                        <td>
                                            {mapHppData ? mapHppData.map_pub_key_id : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>Exponent</b>
                                        </td>
                                        <td>
                                            {mapHppData ? mapHppData.map_pub_key_exponent : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>Modules</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.map_pub_key_modulus : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table classNme='leftAlignedMedium' bordered>
                                <thead>
                                    <tr className="table-success">
                                        <th>
                                            HPP Public Key
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td className="table-success">
                                            <b> ID</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.hpp_pub_key_id : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>Exponent</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.hpp_pub_key_exponent : ''}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className="table-success">
                                            <b>Module</b>
                                        </td>
                                        <td className='breakTableContent'>
                                            {mapHppData ? mapHppData.hpp_pub_key_modulus : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div> : ''}</div>
                        <div>{selectedMenu == 'refund' ? <div>
                            <Table className="centerAligned application" striped bordered>

                                <tbody>
                                    <tr>
                                        <td> <b>Refund</b> </td>
                                        <td><b>  {snowDisplayStatus ? REFUND_APP_STATUS[snowDisplayStatus] : REFUND_APP_STATUS['N']}</b></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div> : ''}</div>
                        <div>{selectedMenu == 'mtls' ? <div><Table className="centerAligned application" striped bordered>

                            <tbody>
                                <tr>
                                    <td> <b>mTLS Connection</b> </td>
                                    <td><b>  {snowDisplayStatus ? MTLS_APP_STATUS[snowDisplayStatus] : MTLS_APP_STATUS['N']}</b></td>
                                </tr>
                            </tbody>
                        </Table></div> : ''}</div >
                        <div>{selectedMenu == 'api' ? <div className='topAligned'>
                            <Button className="centerAlignedItem"
                                color="success"
                                outline
                                onClick={(e) => this.redirectPage('/account/application/')}
                            >
                                Click here to go API Credentials
                            </Button>
                        </div> : ''}</div>

                    </Col>

                </FormGroup>

                <Query />
            </Layout>
        )
    }

}

